// App.js
// Author: Site Crafted

import React, { Suspense, Component, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Helmet } from 'react-helmet';
import NavBar from './NavBar';
import Footer from './Footer';

// Lazy load components to optimize performance and reduce initial bundle size
const Home = React.lazy(() => import('./Home'));
const Services = React.lazy(() => import('./Services'));
const Gallery = React.lazy(() => import('./Gallery'));
const Book = React.lazy(() => import('./Book'));
const Policy = React.lazy(() => import('./Policy'));
const Contact = React.lazy(() => import('./Contact'));
const NotFound = () => <div>Page Not Found</div>;

// ErrorBoundary component to handle unexpected errors gracefully
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an analytics or logging service
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>$1</h1>
          <a href="/">Go back to Home</a>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  usePageView();
  const location = useLocation();
  usePageView();
  return (
    <ErrorBoundary>
      <div className="App">
          <Helmet>
            <title>{getPageTitle(location.pathname)}</title>
            <meta name="description" content={getPageDescription(location.pathname)} />
          </Helmet>
          <NavBar />
          <div className="main-content">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<Home />} />
                <Route path="/pricelist" element={<Services />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/book" element={<Book />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="/contact-me" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </div>
      
    </ErrorBoundary>
  );
}

// Custom hook for tracking page views with Google Analytics
function usePageView() {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);
}

// Function to get dynamic page titles based on the current path
function getPageTitle(pathname) {
  switch (pathname) {
    case '/pricelist':
      return 'Lash with Brittany - Lash Services';
    case '/gallery':
      return 'Lash with Brittany - Lash Extensions Gallery';
    case '/book':
      return 'Lash with Brittany - Book Your Appointment';
    case '/policy':
      return 'Lash with Brittany - Policies for Appointment & Services';
    case '/contact-me':
      return 'Lash with Brittany - Contact Us';
    default:
      return 'Lash with Brittany - Eyelash Extensions in Rochester, NY';
  }
}

// Function to get dynamic page descriptions based on the current path
function getPageDescription(pathname) {
  switch (pathname) {
    case '/pricelist':
      return 'Discover our full range of lash and beauty services at Lash with Brittany in Rochester, NY. From lash extensions to waxing, we have everything you need to look and feel your best.';
    case '/gallery':
      return 'View our gallery of professional lash extension services, including classic, hybrid, and volume lashes. Lash with Brittany in Rochester, NY brings your beauty to life.';
    case '/book':
      return 'Easily book your eyelash extension appointment with Lash with Brittany in Rochester, NY. Select a time that suits you and experience our professional beauty services.';
    case '/policy':
      return 'Review Lash with Brittany policies on booking, cancellations, deposits, and more. Ensure a seamless beauty service experience in Rochester, NY.';
    case '/contact-me':
      return 'Contact Lash with Brittany for professional eyelash services in Rochester, NY. We are here to help you with any inquiries.';
    default:
      return 'Get professional eyelash extensions from Lash with Brittany in Rochester, NY. Book your appointment now to transform your look with confidence.';
  }
}

export default App;