// Footer.js
// Author: Site Crafted

import React from 'react';
import './Footer.css';

function Footer() {
  const handleSocialClick = (platform) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'social_link_click', {
        event_category: 'Footer',
        event_label: platform,
      });
    }
  };

  return (
    <footer className="footer-container">
      <p className="footer-text">© 2024 Lash with Brittany | All Rights Reserved</p>
      <div className="social-media">
        {/* Instagram Link */}
        <a
          href="https://www.instagram.com/lashwithbrittany"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow Lash with Brittany on Instagram"
          onClick={() => handleSocialClick('Instagram')}
        >
          <i className="fab fa-instagram social-icon"></i>
        </a>
        {/* Facebook Link */}
        <a
          href="https://www.facebook.com/lashwithbrittany"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Follow Lash with Brittany on Facebook"
          onClick={() => handleSocialClick('Facebook')}
        >
          <i className="fab fa-facebook social-icon"></i>
        </a>
      </div>
    </footer>
  );
}

export default Footer;